// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "example";

// If you want to add custom CSS you can put it here.
@import "custom";



// -------------------------------------------
// Modal Style
// -------------------------------------------
/* Modal Styling */
.draw-modal {
  max-width: 450px;
  margin: 40px auto;
  padding: 25px;
  border-radius: 12px;
  // background: lightseagreen;
  background: linear-gradient(to left, #0a504a, #38ef7d) !important;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-title {
  font-size: 22px;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
}

/* Form Group Styling */
.form-group {
  width: 100%;
  margin-bottom: 20px;
}

input[type='number'] {
  width: 100%;
  padding: 12px 15px;
  font-size: 16px;
  color: #333;
  border: 2px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  transition: all 0.3s ease;
}

input[type='number']:focus {
  border-color: #007bff;
  background-color: #fff;
  outline: none;
}

/* Error Message */
.error-message {
  color: red;
  font-size: 14px;
  margin-bottom: 20px;
}

/* Button Styling */
.btn {
  padding: 10px 25px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cancel-btn {
  background-color: #f5f5f5;
  color: #333;
}

.cancel-btn:hover {
  background-color: #e0e0e0;
}

.request-btn {
  background: linear-gradient(to right, #373b44, #4286f4) !important;
  // background-color: #007bff;
  color: #fff;
}

.request-btn:hover {
  background-color: #0056b3;
}

/* Modal Footer */
.modal-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

/* Hover effects for button */
.draw-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 20px;
  background-color: #28a745;
  color: white;
  font-size: 16px;
  font-weight: 500;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  transition: transform 0.2s ease-in-out, background-color 0.3s ease;
}

.draw-button:hover {
  background: linear-gradient(to left, #0a504a, #38ef7d) !important;
  // background-color: #218838;
  transform: scale(1.05);
}

.draw-button i {
  margin-right: 8px;
  font-size: 18px;
}

/* Animation for Modal */
@keyframes modal-open {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.draw-modal {
  animation: modal-open 0.3s ease-out;
}







.error-message {
  margin-top: -16px;
  margin-bottom: 20px;
  margin-right: 2px;
  background-color: #ff0000;
  /* Red background color */
  color: white;
  /* Gray text color */
  font-size: 12px;
  /* Adjust the text size as needed */
  padding: 2px;
  border-radius: 4px;
  text-align: center;
}

.error-message-pass {
  background-color: #ff0000;
  /* Red background color */
  color: white;
  /* Gray text color */
  font-size: 14px;
  /* Adjust the text size as needed */
  padding: 5px;
  border-radius: 4px;
  text-align: center;
}

.error-message-profile {
  margin-top: 1px;
  margin-right: 2px;
  background-color: #ff0000;
  color: white;
  font-size: 12px;
  padding: 2px;
  border-radius: 4px;
  text-align: center;
}

#three-dots {
  color: red !important;
}

#avatar img {
  object-fit: cover;
}

#brand img {
  object-fit: contain;
}

#signup-card {
  max-width: '44%';
  width: '44%';
}



// Profile page css
.padding {
  padding: 3rem !important
}

.user-card-full {
  overflow: hidden;
}

.card {
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  border: none;
  margin-bottom: 30px;
}

.m-r-0 {
  margin-right: 0px;
}

.m-l-0 {
  margin-left: 0px;
}

.user-card-full .user-profile {
  border-radius: 5px 0 0 5px;
}

.bg-c-lite-green {
  //     background: -webkit-gradient(linear, left top, right top, from(#f29263), to(#ee5a6f));
  // background: linear-gradient(to right, #ee5a6f, #f29263);
  background: #3c4b64;
}

.user-profile {
  padding: 20px 0;
}

.card-block {
  padding: 1.25rem;
}

.m-b-25 {
  margin-bottom: 25px;
}

.img-radius {
  border-radius: 5px;
}

#page-content h6 {
  font-size: 14px;
}

.card .card-block p {
  line-height: 25px;
}

@media only screen and (min-width: 768px) {

  #signup-card {
    max-width: '100%';
    width: '100%';
  }
}

@media only screen and (min-width: 1400px) {
  #page-content p {
    font-size: 14px;
  }
}

.card-block {
  padding: 1.25rem;
}

.b-b-default {
  border-bottom: 1px solid #e0e0e0;
}

.m-b-20 {
  margin-bottom: 20px;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.card .card-block p {
  line-height: 25px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.text-muted {
  color: #919aa3 !important;
}

.b-b-default {
  border-bottom: 1px solid #e0e0e0;
}

.f-w-600 {
  font-weight: 600;
}

.f-w-500 {
  font-weight: 500;
}

.f-w-700 {
  font-weight: 700;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-t-40 {
  margin-top: 20px;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-t-40 {
  margin-top: 20px;
}

.user-card-full .social-link li {
  display: inline-block;
}

.user-card-full .social-link li a {
  font-size: 20px;
  margin: 0 10px 0 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.slick-prev::before,
.slick-next::before {
  color: black !important;
}

#card-desc p {
  margin-bottom: 5px;
}













/* Add this to your CSS file or component */
.slider-scroll {
  max-height: 700px;
  margin: 0 auto;
  max-width: 100%;
  width: 100%;
  object-fit: contain
}

.modal-overlay .slick-dots li.slick-active button::before {
  color: red !important;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  bottom: 0;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.8);
}

.modal-overlay .slick-arrow.slick-prev {
  position: absolute;
  left: 200px;
  z-index: 9999999;
}

.modal-overlay .slick-arrow.slick-next {
  position: absolute;
  right: 200px;
  z-index: 9999999;
}

.modal-overlay .slick-prev::before,
.modal-overlay .slick-next::before {
  color: white !important;
}

tr div {
  white-space: nowrap;
}

@media only screen and (max-width: 768px) {

  #regis-card{
    margin: 0 !important;
  }

  .modal-overlay .slick-arrow.slick-prev {
    left: 50px;
  }

  .modal-overlay .slick-arrow.slick-next {
    right: 50px;
  }

  .slider-scroll {
    max-height: 400px;
  }

  .container-lg {
    --cui-gutter-x: 0 !important;
  }

  .padding {
    padding: unset !important;
  }

  .row {
    --cui-gutter-x: 0 !important;

  }
}

.row {
  margin-right: unset;
  margin-left: unset;
}

.dash-row {
  position: relative !important;
}





// Notification page design
.section-50 {
  padding: 20px 0 50px 0;
}

.m-b-50 {
  margin-bottom: 50px;
}

.dark-link {
  color: #333;
}

.heading-line {
  position: relative;
  padding-bottom: 5px;
}

.heading-line:after {
  content: "";
  height: 4px;
  width: 75px;
  background-color: #29B6F6;
  position: absolute;
  bottom: 0;
  left: 0;
}

.notification-ui_dd-content {
  margin-bottom: 30px;
}

.notification-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 20px;
  margin-bottom: 7px;
  background: #fff;
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.06);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.06);
}

.notification-list--unread {
  border-left: 2px solid #29B6F6;
}

.notification-list .notification-list_content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.notification-list .notification-list_content .notification-list_img img {
  height: 48px;
  width: 48px;
  border-radius: 50px;
  margin-right: 20px;
}

.notification-list .notification-list_content .notification-list_detail p {
  margin-bottom: 5px;
  line-height: 1.2;
}

.notification-list:hover {
  background: rgba(var(--cui-emphasis-color-rgb), 0.075);
  cursor: pointer;
}







:root {
  --surface-color: #fff;
  --curve: 40;
}

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 4rem 5vw;
  padding: 0;
  list-style-type: none;
}

.card1 {
  position: relative;
  display: block;
  height: 100%;
  border-radius: calc(var(--curve) * 1px);
  overflow: hidden;
  text-decoration: none;
  max-height: 281px;
  height: 281px;
}

.card__image {
  width: 100%;
  height: auto;
}

.card__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  border-radius: calc(var(--curve) * 1px);
  background-color: var(--surface-color);
  transform: translateY(100%);
  transition: 0.2s ease-in-out;
}

.card__header {
  position: relative;
  display: flex;
  align-items: center;
  gap: 2em;
  padding: 1em;
  border-radius: calc(var(--curve) * 1px) 0 0 0;
  background-color: var(--surface-color);
  transform: translateY(-100%);
  transition: 0.2s ease-in-out;
}

.card__arc {
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 100%;
  right: 0;
  z-index: 1;
}

.card__arc path {
  fill: var(--surface-color);
  d: path("M 40 80 c 22 0 40 -22 40 -40 v 40 Z");
}

//   .card1:hover .card__header
//   {
//     transform: translateY(0);
//   }

//   .card1:hover .card__overlay
//    {
//     transform: translateY(0);
//    }

.card__overlay:hover {
  transform: translateY(0);
}

.card__overlay:hover .card__header {
  transform: translateY(0);
  padding: 1em 1em 0.3em 1em;
}

.card__overlay.hovered {
  transform: translateY(0);
}

.card__overlay.hovered .card__header {
  transform: translateY(0);
}

// .card1.hovered .card__header {
//  transform: translateY(0);
// }

// .card1.hovered .card__overlay {
//  transform: translateY(0);
// }


.card__thumb {
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.card__title {
  font-size: 1em;
  margin: 0 0 .3em;
  color: #6A515E;
}

.card__tagline {
  display: block;
  margin: 1em 0;
  font-family: "MockFlowFont";
  font-size: .8em;
  color: #D7BDCA;
}

.card__status {
  font-size: .8em;
  color: #D7BDCA;
}

.card__description {
  padding: 0 2em 1em;
  margin: 0;
  color: #D7BDCA;
  font-family: "MockFlowFont";
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: scroll;
}

.trash-icon {
  width: 30px;
  height: 30px;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  float: right;
  margin-top: -20px;
  position: relative;
  z-index: 1;
}

.loader {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}






// Notification Cards Designs
.container.notification-cust .card {
  background-color: #fff;
  border-radius: 10px;
  border: none;
  position: relative;
  margin-bottom: 30px;
  box-shadow: 0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1), 0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1), 0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12), 0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1);
}

.l-bg-cherry {
  background: linear-gradient(to right, #493240, #f09) !important;
  color: #fff;
}

.l-bg-blue-dark {
  background: linear-gradient(to right, #373b44, #4286f4) !important;
  color: #fff;
}

.l-bg-green-dark {
  background: linear-gradient(to right, #0a504a, #38ef7d) !important;
  color: #fff;
}

.l-bg-orange-dark {
  background: linear-gradient(to right, #a86008, #ffba56) !important;
  color: #fff;
}



.card .card-statistic-3 .card-icon-large .fas,
.card .card-statistic-3 .card-icon-large .far,
.card .card-statistic-3 .card-icon-large .fab,
.card .card-statistic-3 .card-icon-large .fal {
  font-size: 110px;
}

.card .card-statistic-3 .card-icon {
  text-align: center;
  line-height: 50px;
  margin-left: 15px;
  color: #000;
  position: absolute;
  right: -5px;
  top: 20px;
  opacity: 0.1;
}

.l-bg-cyan {
  background: linear-gradient(135deg, #289cf5, #84c0ec) !important;
  color: #fff;
}

.l-bg-green {
  background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%) !important;
  color: #fff;
}

.l-bg-orange {
  background: linear-gradient(to right, #f9900e, #ffba56) !important;
  color: #fff;
}

.l-bg-cyan {
  background: linear-gradient(135deg, #289cf5, #84c0ec) !important;
  color: #fff;
}

.card-statistic-3.p-4 {
  cursor: pointer;
}

.status-tabs {
  display: flex;
  margin-bottom: 10px;
}

.status-tab {
  cursor: pointer;
  color:#000;
  font-weight: bold;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #000;
  border-radius: 5px;
  background-color: #ddd;
  min-width: 100px;
  text-align: center;
}

.status-tab.active {
  background-color: #321fdb;
  color: #fff;
  border-color: #321fdb;
}


.postedjob-singlerow-label {
  text-align: 'left';
  font-weight: 'bold';
  margin-bottom: 0;
  padding-left: 15;
  text-transform: capitalize;
}


.capitalize-text {
  text-transform: capitalize;
  font-weight: 'bold';
}


/* Custom styling for date picker */
.date-picker-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 45%;
}

.date-picker {
  flex: 1;
  margin-right: 10px;
}

.date-picker input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.date-picker input:focus {
  outline: none;
  border-color: #007bff;
}

.date-picker button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
}

.date-picker button:hover {
  background-color: #0056b3;
}

.filterLabel {
  margin-right: 10px;
}

.searchHeader {
    padding: 20px 15px 15px;
    background-color: #ddd;
    border-radius: 6px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
}

.l-filter-bg {
  background: linear-gradient(to left, #eee, #ddd) !important;
  color: #fff;
}

.filter-clear-button {
  width: 100px;
  color: #fff;
  font-size: 17px;
  font-weight: bold;
  padding: 0;
}


// Secet Code Page Style
.main-secret {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  background: #3c4b64;
}
.secret-section {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}
.code-content {
  color: white
}
.copy-code {
  color:  #27ae60;
  font-weight: bold;
}

// Report Details
.detailReport {
  background: #39f;
  color: white;
  border: 1px solid #39f;
  height: 50px;
  font-weight: 600;
}

.postedjob-singlerow-label{
  white-space: nowrap;
  font-weight: 700;
}

.page-item.active {
  z-index: 0;
}

.logoutClass{
  cursor: pointer;
}

.notiDetail{
    background: #39f;
    color: white;
    border: 1px solid #39f;
    height: 50px;
    font-weight: 600;
    padding: 10px;
    text-decoration: none;
}

#signup-btn:hover{
  background-color: #321fdb !important;
}

#signup-btn{
  padding: 8px 30px 8px 30px,
}
