@font-face {
    font-family: Inter;
    src: url(../font/Inter-VariableFont_slnt,wght.woff);
  }
  
  @font-face {
    font-family: Inter-bold;
    src: url(../font/Inter-Bold.woff);
  }
  
  @font-face {
    font-family: Inter-medium;
    src: url(../font/Inter-Medium.woff);
  }

  
  .main-section {
      margin: 0 auto;
  }
  
  .main-section .header  {
     background: #F7F9FA;
     display: flex;
     justify-content: space-between;
     padding: 16px;
     border-radius: 64px;
     position: fixed; 
     width: calc(100vw - 10px);
     top: 5px;
     z-index: 1000; 
     margin: 0 5px;
  }
  
  .logo {
     display: flex;
     align-items: center;
  }
  
  .logo img {
     width: 100%;
     height: 32px;
  }
  
  .join-today {
     font-family: Inter-medium;
     display: flex;
     width: 132px;
     padding: 8px 24px 8px 25px;
     justify-content: center;
     align-items: center;
     background: #6B4EFF;
     border-radius: 48px;
     text-decoration: none;
     font-size: 16px;
     font-style: normal;
     color: #fff;
  }
  
  // .welcome-section {
  //    margin: 29px 20px 0px 20px;
  //    background-image: url('../assets/images/landingpageimages/bgscreens.png');
  //    background-position: right;
  //    background-repeat: no-repeat;
  //    height: 680px;
  //    position: relative;
  //    display: flex;
  //    align-items: center;
  //    background-color: #F7F9FA;
  //    border-bottom-left-radius: 20px;
  //    border-bottom-right-radius: 20px;
  // }
  
  .wel-content {
    display: flex;
    width: 603px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
  }
  
  .wel-content h2 {
    font-family: Inter-bold;
    color: #090A0A;
    font-size: 48px;
    font-style: normal;
    line-height: 56px; 
    margin-bottom: 0;
  }
  
  .wel-content p {
    color: #72777A;
    font-family: Inter-bold;
    font-size: 24px;
    font-style: normal;
    line-height: 32px;
    margin-top: 20px;
  }
  
  .wel-join-btn {
   font-family: Inter-medium;
   display: flex;
   padding: 16px 24px 16px 25px;
   justify-content: center;
   align-items: center;
   background: #6B4EFF;
   border-radius: 48px;
   color: #FFF;
   text-align: center;
   font-size: 16px;
   font-style: normal;
   line-height: 16px; 
   text-decoration: none;
   margin-top: 30px;
  }
  
  .process-section {
      margin: 100px 20px 100px 20px;
  }
  
  .process-section h2 {
    color: #090A0A;
    text-align: center;
    font-family: Inter-bold;
    font-size: 32px;
    font-style: normal;
    line-height: 36px;
  }
  
  .process-inner-sec {
    display: flex;
    margin-top: 60px;
  }
  
  .inner-depth-sec {
    text-align: center;
    width: calc(100%/3);
    max-width: calc(100%/3);
  }
  
  .inner-depth-sec h2 {
    color: #090A0A;
    text-align: center;
    font-family: Inter-bold;
    font-size: 24px;
    font-style: normal;
    line-height: 32px;
    margin-bottom: 0;
    margin-top: 1.5rem;
  }
  
  .inner-depth-sec p {
   color: #090A0A;
   text-align: center;
   font-family: Inter;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
   margin-top: 10px;
  }
  
  .process-join-btn {
   display: flex;
   justify-content: center;
   align-items: center;
   margin: 20px 20px 0px 20px;
  }
  
  .person-section {
   background: #F7F9FA;
   margin: 0px 20px 0px 20px;
   padding: 80px 0px 80px 0px;
   border-radius: 32px;
   display: flex;
   margin-bottom: 20px;
  }
  
  .person-section h2 {
   margin: 1.5rem 0 !important;
   color: #090A0A;
   font-family: Inter-bold;
   font-size: 32px;
   font-style: normal;
   line-height: 36px; 
  }
  
  .person-img-sec {
   width: 40%; 
   text-align: right;
  }
  
  .person-content-sec {
   width:30%
  }
  
  .person-section ul {
    list-style: none;
    padding: 0;
  }
  
  .person-section ul li {
    display: flex;
    align-items: center;
  }
  
  .person-section ul li img {
    margin-right: 10px; 
    margin-top: 10px;
    margin-bottom: 4px;
  }
  
  .person-section ul li p {
    margin-top: 1rem;
   color: #090A0A;
   font-family: Inter;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 20px; 
   margin-bottom: 10px;
  }
  
  .person-btn-section {
      display: flex;
  }
  
  .owner-section {
   background: #E7E7FF;
   margin: 0px 20px 0px 20px;
   border-radius: 32px;
   display: flex;
   padding: 96px;
  }
  
  .owner-content-sec {
   width:50%
  }
  
  .owner-img-sec {
   width: 50%; 
   text-align: right;
  }
  
  .owner-section ul {
    list-style: none;
    padding: 0;
  }
  
  .owner-section ul li {
    display: flex;
    align-items: center;
  }
  
  .owner-section ul li img {
    margin-right: 10px; 
    margin-top: 10px;
    margin-bottom: 4px;
  }
  
  .owner-section ul li p {
   color: #090A0A;
   font-family: Inter;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 20px; 
   margin-top: 1rem;
   margin-bottom: 10px;
  }
  
  .owner-section h2 {
   margin: 1.5rem 0 !important;
   color: #090A0A;
   font-family: Inter-bold;
   font-size: 32px;
   font-style: normal;
   line-height: 36px; 
  }
  
  .convenience {
   margin: 40px 20px 120px 20px;
  }
  
  .convenience h2 {
   color: #090A0A;
   text-align: center;
   font-family: Inter-bold;
   font-size: 32px;
   font-style: normal;
   line-height: 36px;
   padding-top: 40px;
   margin: 1.5rem 0 !important;
  }
  
  .convenience p {
   max-width: 814px;
   width: 814px;
   color: #090A0A;
   text-align: center;
   font-family: Inter;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px; 
   margin: 0 auto;
  }
  
  .conv-trans-img-sec {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }
  
  .stop-btn {
    position: absolute;
  } 
  
  .watchlist-section {
    position: relative;
    background: #E7E7FF;
    margin: 20px;
    padding: 96px 8px;
    border-radius: 32px;
  }
  
  .watchlist-logo-right {
    position: absolute;
    right: 0;
    bottom: 0;
  }
  
  .watchlist-logo-right img {
   border-bottom-right-radius: 32px;
  }
  
  .watchlist-section h2 {
   margin: 1.5rem 0 !important;
   color: #090A0A;
   text-align: center;
   font-family: Inter-bold;
   font-size: 32px;
   font-style: normal;
   line-height: 36px; 
  }
  
  .form-section {
   display: flex;
   flex-direction: column;
   width: 394px;
   margin: 0 auto;
  }
  
  .form-section input {
   border-radius: 8px;
   border: 1px solid #E3E5E5;
   background: #FFF;
   padding: 12px;
   margin-bottom: 10px;
  }
  
  .form-section input::placeholder {
   color: #72777A;
   font-family: Inter;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 16px;
  }
  
  .form-section input:focus {
   border: 1px solid #6B4EFF !important;
  }
  
  .form-section input:focus-visible {
    border: none !important;
  }
  
  .form-section .wel-join-btn {
    border : none;
    margin-top: 10px;
  } 
  
  .footer-main-section {
    margin: 60px 20px 20px 20px;
  }
  
  .footer-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .footer-section ul li a {
    text-decoration: none;
  }
  
  .footer-section ul {
    list-style: none;
    display: flex;
    padding: 0;
    margin-bottom: 0;
  }
  
  
  .footer-section .section-links ul li a {
   color: #090A0A;
   font-family: Inter;
   font-size: 14px;
   font-style: normal;
   font-weight: 400;
   line-height: 20px; 
   padding-right: 32px;
  }
  
  .footer-section .section-icons ul li {
    padding-left: 15px;
  }
  
  .footer-main-section p {
    margin: 0;
    color: #72777A;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

//   .PhoneInputCountryIcon {
//     height: 28px !important;
//     margin-bottom: 9px !important;
//     width: unset !important;
//   }

//   .PhoneInputCountrySelectArrow {
//     margin-bottom: 10px !important;
//     opacity: 1 !important;
//     color: red;
//   }

.react-tel-input .form-control {
    border: none !important;
}

.react-tel-input .flag-dropdown {
    border: unset !important;
}

.react-tel-input {
    margin-bottom: 10px;
}


// Stripe Payment Modal Style
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 9999;
  // background-color: rgba(200, 200, 255, 0.9) !important;
  background-color: rgba(10, 10, 10, 0.7) !important;
}

.centered-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%; /* Adjust the width as needed */
  max-width: 400px; /* Set a maximum width if needed */
  background-color: white;
  padding: 20px;
  border-radius: 8px;
}

.centered-modal-loginfo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%; /* Adjust the width as needed */
  max-width: 1000px; /* Set a maximum width if needed */
  background-color: white;
  padding: 20px;
  border-radius: 8px;
}

// .ReactModal__Content.ReactModal__Content {
//   width: 600px;
//   margin: 0 auto;
//   height: 400px;
// }

.pay-btn {
  font-family: Inter-medium;
  display: flex;
  width: 90px;
  padding: 5px 8px 5px 8px;
  justify-content: center;
  align-items: center;
  background: #6B4EFF;
  border-radius: 7px;
  text-decoration: none;
  font-size: 16px;
  font-style: normal;
  color: #fff;
  margin-top: 24px;
  margin-bottom: 10px;
}
    
  
  @media(min-width: 768px) and (max-width: 1080px) {
    .footer-section {
      flex-direction: row !important;
    }
  }
  
  
  @media(max-width: 1080px) {
  
    .process-inner-sec {
     flex-direction: column;
   }
    .inner-depth-sec, 
    .person-img-sec, 
    .person-img-sec img,
    .person-content-sec,
    .owner-img-sec, 
    .owner-img-sec img,
    .owner-content-sec,
    .form-section,
    .convenience p,
    .conv-trans-img-sec img
     {
     width: 100%;
     max-width: 100%;
    }
   .person-section, .owner-section {
    flex-direction: column;
    padding: 20px;
   }
   .watchlist-section {
    padding: 40px 20px;
   }
   .convenience {
    margin: 40px 20px 80px 20px;
   }
   .owner-img-sec img {
    margin-top: 40px;
   }
   /*.welcome-section {
    background-image: none;
   }*/
   .footer-section {
    flex-direction: column;
   }
   .footer-section .section-links ul li a {
    padding-right: 20px;
   }
   .welcome-section {
    margin: 0px 20px 0px 20px;
   }
   .process-section {
    margin: 70px 20px 100px 20px;
   }
   .wel-content h2 {
    font-size: 40px;
    line-height: 44px;
  }
  .form-section {
    position: relative;
    z-index: 999;
  }
   
  }

  @media (max-width: 768px) {
    .watchlist-logo-right img {
      width: 100%;
    }
    .watchlist-logo-right {
     width: 100%;
   }
   
   }